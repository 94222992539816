import { Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Reset from './components/ResetPassword';
import Error from './components/Error';
import Success from './components/Success';

function App() {
  return (<>
      <Routes>
        <Route path="/verify/:token" element={<Reset />} />
        {/*<Route path="*" element={<Error />} />*/}
      </Routes>
    </>);
}

export default App;
