import { Form, Input, Button } from 'antd';
import axios from 'axios';
import { Card } from 'antd';

function ChangePassword({ token, handleSetSuccess, handleSetError }) {

  const handleFormSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/forgot-password`,
        {
          new_password: values.new_password,
          token: token,
        }
      );

      console.log(response)
      if (response.data.status === 1) {
        handleSetSuccess(response.data.message);
        handleSetError(false)
      } else {
        handleSetSuccess(false);
        handleSetError(response.data.message);
      }

    } catch (error) {
      console.log(error)
      handleSetSuccess(false);
      handleSetError('Failed to change password.');
    }
  };

  return (<Card className='card'>

    <img className='logo' src='/assets/images/logo.svg'></img>
    <img className='status-image' src='/assets/images/password.png'></img>
    <div className='title'>Change Your Password</div>


    <Form className='changeform' layout='vertical' onFinish={handleFormSubmit}>
      <Form.Item
        name="new_password"
        label="New Password"
        rules={[
          {
            validator: (_, value) => {
              if (value && value.replace(/\s/g, '').length < 8) {
                return Promise.reject('Password must be at least 8 characters without spaces');
              }
              return Promise.resolve();
            }
          },
        ]}
      >
        <Input.Password className='passinput' placeholder='New Password' />
      </Form.Item>
      <Form.Item >
        <Button className='submit' htmlType="submit">
          Change Password
        </Button>
      </Form.Item>
    </Form>
  </Card>)
}

export default ChangePassword