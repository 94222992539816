import React from 'react'
import { Card } from 'antd';

function Success({success}) {
  return (
    <Card className='card'>
    <img className='logo' src='/assets/images/logo.svg'></img>
    <img className='status-image' src='/assets/images/password.png'></img>
    <div className='title'>Password Changed</div>
    <p className='message'>
    Open Deem-it! app and login with new password details.
    </p>
    {/*success && 
    <p className='api-message'>
      Details : {success}
    </p>
  */}
  </Card>
  )
}

export default Success