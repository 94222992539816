import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import ChangePassword from './ChangePassword';
import Error from './Error'
import Success from './Success'

import { Layout } from 'antd';
const { Content } = Layout;

const ResetPasswork = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [loading, setLoading] = useState(false);
    const [tokenOk, setTokenOk] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        verifyToken()
    }, [token])

    const verifyToken = async () => {
        try {
            setLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/user/verify-link/${token}`
            );
            if (response.data.status === 1) {
                setTokenOk(true);
                //setSuccess(response.data.message);
                setLoading(false)
                setError(false)
            } else {
                setSuccess(false)
                setError(response.data.message);
            }
            setLoading(false)
        } catch (error) {
            setError('Failed. Please try again.');
            setLoading(false)
        }
    }

    const handleSetSuccess = (value)=>{
        setSuccess(value)
    }

    const handleSetError = (value)=>{
        setError(value)
    }

    return (
        <Layout >
             <Content className='container'>
            {loading && <p>LOADING...</p>}
            {error && <Error error={error}/>}
            
            {success && <p><Success success={success}/></p>}

            {tokenOk && success === false && <>
                <ChangePassword token={token} handleSetSuccess={handleSetSuccess} handleSetError={handleSetError} setLoading={setLoading} />
            </>}
            </Content>
        </Layout>
    );
};

export default ResetPasswork;
