import React from 'react'
import { Card } from 'antd';

function Error({ error }) {
  return (<Card className='card'>
    <img className='logo' src='/assets/images/logo.svg'></img>
    <img className='status-image' src='/assets/images/error.png'></img>
    <div className='title'>Something went wrong!</div>
    <p className='message'>
      Password reset link expired or wrong link, try again after sometime
    </p>
    {error && 
    <p className='api-message'>
      Details : {error}
    </p>
    }
    <div className='more'>
      If problem persists {' '}
      <a target="_blank" href='https://deemit.app/contact'>Contact Us</a>
    </div>

  </Card>)
}

export default Error